import * as React from 'react';
import styled from '../../config/theme';
import { Link } from 'gatsby';
import logo from '../../assets/branding/logo_full.png';

const Navigation = () => (
  <NavWrapper>
    <BrandWrapper to="/">
      <BrandImage src={logo} alt="fourc logo" />
    </BrandWrapper>
  </NavWrapper>
);

const NavWrapper = styled.nav`
  display: flex;
  padding: 20px;
  position: absolute;
  width: 100%;
  top: 0;
`;

const BrandWrapper = styled(Link)`
  max-height: 90px;
  min-height: 50px;
  min-width: 80px;
`;

const BrandImage = styled.img`
  border-radius: 5px;
  max-height: 90px;
  min-height: 50px;
  min-width: 80px;
  max-width: 100%;
`;

export default Navigation;
