import * as React from 'react';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';
import ServiceBox from './ServiceBox';
import critical from '../../assets/icons/critical.svg';
import communication from '../../assets/icons/communication.svg';
import creativity from '../../assets/icons/creativity.svg';
import collaboration from '../../assets/icons/collaboration.svg';
import { media } from '../../config/theme/utils';
import KoenImg from './KoenImg';

const Services = () => (
  <FlexWrapper>
    <Flex flexWrap="wrap" align="center">
      <ServiceBoxWrapper>
        <ServiceBox iconSrc={critical} text={`Customer Critical thinking `} />
        <ServiceBox iconSrc={communication} text={'Communication'} />
        <ServiceBox iconSrc={creativity} text={'Creativity'} />
        <ServiceBox iconSrc={collaboration} text={'Concept Collaboration'} />
      </ServiceBoxWrapper>
      <TextBoxWrapper>
        <Flex alignItems="center">
          <KoenImg />
          <StandsForContainer>
            <Title>FourC stands for</Title>
            <Text>
              <b>professionalism</b>, boosting your confidence and <b>improving sales skills</b>. Welcome to the first
              step of boosting your business with me.
            </Text>
          </StandsForContainer>
        </Flex>
        <br />
        <br />
        <br />
        <Flex>
          <Text>
            <p>You can rely on me and my training program to:</p>
            <ul>
              <LiSpacer>
                Provide skills training, for individuals and for groups.
                <br /> (e.g. teaching sales skills)
              </LiSpacer>
              <LiSpacer>Follow-up on the sales team.</LiSpacer>
              <LiSpacer>Teach salesmen how to organize their work, planning and day-to-day approach.</LiSpacer>
            </ul>
          </Text>
        </Flex>
      </TextBoxWrapper>
    </Flex>
  </FlexWrapper>
);

const LiSpacer = styled.li`
  padding-bottom: 8px;
`;

const StandsForContainer = styled.div`
  padding-left: 50px;

  ${media.tablet`
    padding-left: 30px;
  `};
  ${media.mobile`
    padding-left: 14px;
  `};
`;

const Text = styled.div`
  display: inline;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
`;

const Title = styled.h2`
  text-align: left;
  font-size: 40px;
  padding-bottom: 20px;
`;

const TextBoxWrapper = styled.div`
  max-width: 850px;
  margin: auto;
  padding: 40px 0 0 0;

  ${media.mobile`
    padding: 0px 20px 60px;
  `}
`;

const FlexWrapper = styled.section`
  align-items: center;
  margin: auto;
  max-width: 1200px;

  ${media.mobile`
    padding: 40px 10px 10px 10px;
  `}
  ${media.tablet`
    padding: 80px 80px 10px 80px;
  `}
`;

const ServiceBoxWrapper = styled.div`
  justify-content: flex-end;
  margin: -50px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-bottom: 60px;

  ${media.tabletAndBelow`
    margin: -140px auto 0;
  `}
`;

export default Services;
