import React from 'react';
import styled from '../../config/theme';

const AboutMe = () => (
  <Container>
    <Wrapper>
      I have been working as a chief operations officer for multiple big business for the last <b>25 years</b>, in
      Benelux, France and Germany.
      <br />I specialize in <b>sales</b> of consumer and construction goods.
      <br />
      My clients trust me to increase their sales and let their <b>sales team thrive</b>, in order to move their
      business forward. <b>Contact </b>
      me today and learn how to <b>boost your sales</b> with my expertise!
    </Wrapper>
  </Container>
);

const Container = styled.div`
  background: rgba(0, 174, 150, 0.2);
  padding: 40px 0 100px 0;
`;
const Wrapper = styled.div`
  padding: 40px;
  max-width: 1000px;
  margin: auto;
`;

export default AboutMe;
