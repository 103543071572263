import React from 'react';
import styled from 'styled-components';
import background from '../assets/images/background.jpg';
import GlobalCollection from '../config/GlobalCollection';
import { media } from '../config/theme/utils';
import Clients from '../modules/clients/Clients';
import Contact from '../modules/contact/Contact';
import Navigation from '../modules/navigation/Navigation';
import Services from '../modules/services/Services';
import AboutMe from '../modules/aboutMe/AboutMe';

const Index = () => {
  return (
    <GlobalCollection>
      <Hero>
        <Navigation />
        <HeroText>
          <h2>
            Sales training and personal growth
            <br />
            based on the 4 <Strong>C</Strong>'s
          </h2>
        </HeroText>
      </Hero>
      <Services />
      <Clients />
      <AboutMe />
      <Contact />
    </GlobalCollection>
  );
};

const Strong = styled.p`
  font-weight: 900;
  display: inline;
`;

const HeroText = styled.div`
  padding: 200px 0 120px;
  align-self: center;

  ${media.tabletAndBelow`
    padding: 20px 0 10px;
  `}
`;

const Hero = styled.div`
  background: url(${background}) no-repeat center right fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  text-align: center;
  color: #ffffff;
  min-height: 40vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  ${media.mobile`
    min-height: 75vh;
  `}
`;

export default Index;
